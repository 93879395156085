import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './KubeWhisper.css';

export default function LandingPage() {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  const handleEmailChange = (e) => setEmail(e.target.value);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      body: JSON.stringify({
        firstName: 'KubeWhisper',
        lastName: 'Waitlist',
        email: email,
        topic: 'KubeWhisperAI',
        message: 'Joining KubeWhisper waitlist',
      }),
    };

    const response = await fetch(
      'https://tgllk21vo1.execute-api.us-east-1.amazonaws.com/prod/form',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      }
    );

    if (response.ok) {
      sessionStorage.setItem('waitlistJoined', 'true');
      navigate('/kubewhisper/thank-you');
    } else {
      console.error('Failed to submit the waitlist form');
    }
  };

  useEffect(() => {
    // Dynamically load the Asciinema player script
    const script = document.createElement('script');
    script.src = 'https://asciinema.org/a/qSe2TBorphMGc7FjeUGhNkV1I.js';
    script.id = 'asciicast-qSe2TBorphMGc7FjeUGhNkV1I';
    script.async = true;

    const videoContainer = document.getElementById('asciinema-player');
    if (videoContainer) {
      videoContainer.appendChild(script);
    }

    // Cleanup: remove script on unmount
    return () => {
      if (videoContainer) {
        videoContainer.innerHTML = '';
      }
    };
  }, []);

  return (
    <div className="landing-container">
      {/* Hero Section */}
      <section className="hero">
        <h1 className="hero-title">KubeWhisper</h1>
        <p className="hero-quote">
          Unleash the power of Kubernetes with KubeWhisper—your AI-powered
          assistant for seamless deployments.
        </p>
      </section>

      {/* Waitlist Form Section */}
      <section className="waitlist-section">
        <h2 className="waitlist-subtitle">Join the KubeWhisper Waitlist</h2>
        <p className="waitlist-text">
          Drop your email below to join the waitlist for KubeWhisper, and I'll
          contact you with the installation details shortly. :)
        </p>
        <form onSubmit={handleSubmit} className="waitlist-form">
          <label htmlFor="email" className="waitlist-label">
            <input
              type="email"
              className="waitlist-input"
              name="email"
              id="email"
              placeholder="Enter your email"
              required
              value={email}
              onChange={handleEmailChange}
            />
          </label>
          <button
            type="submit"
            className="btn btn-primary waitlist-submit-btn"
          >
            Join Waitlist
          </button>
        </form>
      </section>

      {/* Video Demo Section */}
      <section className="demo-section">
        <h2 className="demo-title">Watch KubeWhisper in Action</h2>
        <div id="asciinema-player" className="video-container"></div>
      </section>
    </div>
  );
}