import { useEffect } from "react";

export default function HeroSection() {
  useEffect(() => {
    // Add preload link for the image
    const link = document.createElement("link");
    link.rel = "preload";
    link.as = "image";
    link.href = "./img/hero_img3.webp"; // Preferably point to a WebP or AVIF file for efficiency
    document.head.appendChild(link);

    // Cleanup to avoid duplicating link on re-renders
    return () => {
      document.head.removeChild(link);
    };
  }, []);

  return (
    <section id="heroSection" className="hero--section">
      <div className="hero--section--content--box">
        <div className="hero--section--content">
          <p className="section--title">Hey, I'm Branko</p>
          <h1 className="hero--section--title">
            <span className="hero--section-title--color">DevOps</span>{" "}
            <br />
            Consultant
          </h1>
          <p className="hero--section-description">
            If you are a small or medium-sized business, I can help you reduce
            cloud costs, improve automation practices, and streamline your DevOps
            processes.
          </p>
        </div>
        <button className="btn btn-primary">Get In Touch</button>
      </div>
      <div className="hero--section--img">
        <picture>
          <source
            srcSet="./img/hero_img3.avif"
            type="image/avif"
          />
          <source
            srcSet="./img/hero_img3.webp"
            type="image/webp"
          />
          <img
            src="./img/hero_img3.png"
            id="hero--image"
            alt="Hero Section"
          />
        </picture>
      </div>
    </section>
  );
}