import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import "./ThankYou.css";

export default function ThankYou() {
    const navigate = useNavigate();

    useEffect(() => {
      const formSubmitted = sessionStorage.getItem("formSubmitted");

      if (formSubmitted !== "true") {
        // If the form was not submitted, redirect to the home page
        navigate('/');
      }

      // Cleanup function to run when the user leaves the thank-you page
      window.onbeforeunload = function() {
        sessionStorage.clear();
     }

    }, [navigate]);

    return (
      <div className="thank-you-container">
        <div className="thank-you-content">
          <h2 className="thank-you-subtitle">Thank you!</h2>
          <p className="thank-you-text">
            I've received your message. I'll contact you shortly with meeting details.
          </p>
          <Link to="/" className="btn btn-primary">
            Back to Home
          </Link>
        </div>
      </div>
    );
}