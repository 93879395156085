import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Lambda.css';

export default function LandingPage() {
    const [email, setEmail] = useState('');
    const navigate = useNavigate();

    const handleEmailChange = (e) => setEmail(e.target.value);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const payload = {
            body: JSON.stringify({
                firstName: 'Lambda',
                lastName: 'Factory',
                email: email,
                topic: 'LambdaFactoryWaitlist',
                message: 'Joining Lambda Factory waitlist',
            }),
        };

        const response = await fetch(
            'https://tgllk21vo1.execute-api.us-east-1.amazonaws.com/prod/form',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            }
        );

        if (response.ok) {
            sessionStorage.setItem('waitlistJoined', 'true');
            navigate('/lambda/thank-you');
        } else {
            console.error('Failed to submit the waitlist form');
        }
    };

    return (
        <div className="landing-container">
            {/* Hero Section */}
            <section className="hero">
                <h1 className="hero-title">Lambda Factory</h1>
                <p className="hero-quote">Save money on simple website form processing. Get one-click deployment.</p>
            </section>

            {/* Waitlist Form Section */}
            <section className="waitlist-section">
                <h2 className="waitlist-subtitle">Join the Lambda Factory Waitlist</h2>
                <p className="waitlist-text">
                    Sign up for early access to Lambda Factory and get exclusive perks!
                </p>
                <form onSubmit={handleSubmit} className="waitlist-form">
                    <label htmlFor="email" className="waitlist-label">
                        <input
                            type="email"
                            className="waitlist-input"
                            name="email"
                            id="email"
                            placeholder="Enter your email"
                            required
                            value={email}
                            onChange={handleEmailChange}
                        />
                    </label>
                    <button
                        type="submit"
                        className="btn btn-primary waitlist-submit-btn"
                    >
                        Join Waitlist
                    </button>
                </form>
            </section>

            {/* Value of Templates Section */}
            <section className="value-section">
                <h2 className="value-title">Unlock the Power of AWS Lambda Templates</h2>
                <p className="value-text">AWS Lambda is not only cost-effective but also serverless and scales on demand.</p>
                <ul className="value-list">
                    <li><b>Save Money</b>: Free tier covers 1M requests/month.</li>
                    <li><b>Focus on Code</b>: Forget server maintenance—just configure and deploy.</li>
                    <li><b>Fast Setup</b>: Go live in minutes with ready-to-use templates.</li>
                    <li><b>Scale on Demand</b>: Handle any traffic spike, hassle-free.</li>
                    <li><b>Seamless Integration</b>: Integrate effortlessly on your website and start collecting form data.</li>
                </ul>
            </section>


            {/* What's Included Section */}
            <section className="features-section">
                <h2 className="features-title">What's Included</h2>
                <div className="features-grid">
                    <div className="feature-card">
                        <div className="feature-icon">📄</div>
                        <div className="feature-title">Lambda Templates</div>
                        <p className="feature-description">Ready-to-use templates for processing forms.</p>
                    </div>
                    <div className="feature-card">
                        <div className="feature-icon">🚀</div>
                        <div className="feature-title">One-Click Deployment</div>
                        <p className="feature-description">Deploy directly to AWS with a single click.</p>
                    </div>
                    <div className="feature-card">
                        <div className="feature-icon">📞</div>
                        <div className="feature-title">Setup Assistance</div>
                        <p className="feature-description">30-minute call to help with setup.</p>
                    </div>
                    <div className="feature-card">
                        <div className="feature-icon">🔒</div>
                        <div className="feature-title">Pre-Configured Security</div>
                        <p className="feature-description">Secure settings to protect user data.</p>
                    </div>
                    <div className="feature-card">
                        <div className="feature-icon">📊</div>
                        <div className="feature-title">Real-Time Monitoring</div>
                        <p className="feature-description">Track form submissions as they happen.</p>
                    </div>
                    <div className="feature-card">
                        <div className="feature-icon">📈</div>
                        <div className="feature-title">Automatic Scaling</div>
                        <p className="feature-description">Handles traffic surges effortlessly.</p>
                    </div>
                    <div className="feature-card">
                        <div className="feature-icon">📖</div>
                        <div className="feature-title">Detailed Documentation</div>
                        <p className="feature-description">Easy-to-follow docs for customization.</p>
                    </div>
                    <div className="feature-card">
                        <div className="feature-icon">💡</div>
                        <div className="feature-title">Exclusive Updates</div>
                        <p className="feature-description">Access to new templates and features.</p>
                    </div>
                </div>
            </section>

            {/* FAQ Section */}
            <section className="faq-section">
                <h2 className="faq-title">Frequently Asked Questions</h2>
                <div className="faq-list">
                    <div className="faq-item">
                        <div className="faq-question">What is AWS Lambda?</div>
                        <div className="faq-answer">AWS Lambda is a serverless computing service by Amazon Web Services that runs code in response to events and automatically manages the compute resources.</div>
                    </div>
                    <div className="faq-item">
                        <div className="faq-question">How do I deploy the templates?</div>
                        <div className="faq-answer">With one-click deployment option, you can deploy the Lambda templates directly to AWS in seconds.</div>
                    </div>
                    <div className="faq-item">
                        <div className="faq-question">Is there a setup fee?</div>
                        <div className="faq-answer">No, there's no setup fee! You only pay once for the lifetime access to Lambda templates.</div>
                    </div>
                    <div className="faq-item">
                        <div className="faq-question">Can I customize the templates?</div>
                        <div className="faq-answer">Absolutely! Templates are designed to be easily customizable to fit your needs.</div>
                    </div>
                    <div className="faq-item">
                        <div className="faq-question">What support is provided?</div>
                        <div className="faq-answer">I offer a 30-minute call to assist with setup and ongoing email support.</div>
                    </div>
                </div>
            </section>


        </div>
    );
}
