import AboutMe from "../AboutMe";
import ContactMe from "../ContactMe";
import Footer from "../Footer";
import HeroSection from "../HeroSection";
import MyPortfolio from "../MyPortfolio";
import MySkills from "../MySkills";
import Testimonial from "../Testimonials";
import MyServices from "../MyServices";
import ServicesImpact from "../ServicesImpact";
import ScheduleMeeting from "../FreeMeeting";

export default function Home() {
  return (
    <>
      <HeroSection />
      <MySkills />
      <MyServices />
      <ServicesImpact />
      <ScheduleMeeting />
      <MyPortfolio />
      <Testimonial />
      <ContactMe />
      <Footer />
    </>
  );
}
