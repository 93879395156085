import React from "react";
import { Link } from "react-router-dom";

export default function Cookies() {
  return (
    <div className="cookies-container">
      <div className="cookies-content">
        <h2 className="cookies-subtitle">No Cookies</h2>
        <p className="cookies-text">
          I don't use cookies on this site. :)
        </p>
        <Link to="/" className="btn btn-primary">
          Back to Home
        </Link>
      </div>
    </div>
  );
}