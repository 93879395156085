export default function ScheduleMeeting() {
  const handleButtonClick = () => {
    ReactGA.event({
      category: "User",
      action: "Clicked Button",
      label: "Schedule Meeting",
    });
  };
    return (
      <section className="schedule-meeting-section" id="schedule-meeting">
        <div className="schedule-meeting-content">
          <h2 className="schedule-meeting-heading">Schedule a Free Consultation</h2>
          <p className="schedule-meeting-text">
            Let's discuss how I can help streamline your DevOps, Cloud Architecture, or MLOps processes. Schedule a free 30-minute consultation with me to get started!
          </p>
          <a
            href="https://calendly.com/petric-branko2000/30min"
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-primary"
          >
            Schedule Free Meeting
          </a>
        </div>
      </section>
    );
  }