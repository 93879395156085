import React from "react";
import { Link } from "react-router-dom";
import blogData from "../../data/blogs.json";
import "./Blog.css";

export default function BlogPage() {
    return (
        <section className="blog-page">
            <h1 className="blog-page-title">Blog Posts</h1>
            {blogData?.blogs?.length > 0 ? (
                <div className="blog-list">
                    {blogData.blogs.map((blog, index) => (
                        <div key={index} className="blog-card">
                            <Link to={`/blog/${blog.slug}`}>
                                <img className="blog-card-cover" src={blog.cover} alt={blog.title} />
                            </Link>
                            <div className="blog-card-content">
                                <h2 className="blog-card-title">{blog.title}</h2>
                                <p className="blog-card-excerpt">{blog.excerpt}</p>
                                <p className="blog-card-date">{blog.date}</p>
                                <Link to={`/blog/${blog.slug}`} className="read-more">
                                    Read More
                                </Link>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <p className="no-blogs-message">No blogs yet! :)</p>
            )}
        </section>
    );
}