import React, { useState, useEffect } from "react";
import data from "../../data/services.json";

export default function MyServices() {
  const [activeTab, setActiveTab] = useState("DevOps");

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          }
        });
      },
      { threshold: 0.1 }
    );

    const observeCards = () => {
      const cards = document.querySelectorAll(".services--section--card, .certificates--section--card");
      cards.forEach((card) => {
        observer.observe(card);
      });
    };

    // Observe cards when component mounts or activeTab changes
    observeCards();

    return () => {
      observer.disconnect(); // Cleanup observer on unmount or tab switch
    };
  }, [activeTab]);

  // Fetch the services for the currently active tab (pillar)
  const getFilteredServices = () => {
    return data.services[activeTab]?.servicesList || [];
  };

  // Fetch the certificates for the currently active tab (pillar)
  const getCertificates = () => {
    return data.services[activeTab]?.certificates || [];
  };

  // Store certificates for conditional rendering
  const certificates = getCertificates();

  return (
    <section className="services--section" id="myServices">
      <div className="portfolio--container">
        <p className="section--title">My Skills</p>
        <h2 className="services--section--heading">My Services</h2>
        <div className="tabs--container">
          <button
            className={`tab ${activeTab === "DevOps" ? "active" : ""}`}
            onClick={() => setActiveTab("DevOps")}
          >
            DevOps
          </button>
          <button
            className={`tab ${activeTab === "AI" ? "active" : ""}`}
            onClick={() => setActiveTab("AI")}
          >
            AI
          </button>
          <button
            className={`tab ${activeTab === "Python" ? "active" : ""}`}
            onClick={() => setActiveTab("Python")}
          >
            Python Development
          </button>
        </div>
      </div>

      <div className="services--section--container">
        {getFilteredServices().map((item) => (
          <div key={item.id} className="services--section--card">
            <div className="services--section--img">
              <img className="product--img" src={item.src} alt={item.title} />
            </div>
            <div className="services--section--card--content">
              <h3 className="services--section--title">{item.title}</h3>
              <p className="services--section--description">{item.description}</p>
            </div>
          </div>
        ))}
      </div>

      {/* Conditionally render the certificates section */}
      {certificates.length > 0 && (
        <div className="certificates--section--container">
          <h3 className="certificates--section--heading">Certificates</h3>
          {certificates.map((certificate, index) => (
            <div key={index} className="certificates--section--card">
              <div className="certificates--section--img">
                <a href={certificate.link} target="_blank" rel="noopener noreferrer">
                  <img className="certificate--img" src={certificate.image} alt={certificate.title} />
                </a>
              </div>
              <div className="certificates--section--card--content">
                <h4 className="certificates--section--title">{certificate.title}</h4>
                <p className="certificates--section--issuer">Issued by {certificate.issuer}</p>
              </div>
            </div>
          ))}
        </div>
      )}
    </section>
  );
}