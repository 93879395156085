import React, { useEffect, useState } from "react";
import data from "../../data/index.json";

export default function ServicesImpact() {
  const [hasAnimated, setHasAnimated] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !hasAnimated) {
            setHasAnimated(true);
          }
        });
      },
      { threshold: 0.3 }
    );

    const section = document.querySelector("#services-impact");
    observer.observe(section);

    return () => observer.disconnect();
  }, [hasAnimated]);

  return (
    <section className="services-impact-section" id="services-impact">
      <h2 className="services-impact-heading">Stats</h2>
      <div className="services-impact-container">
        {data?.servicesImpact?.map((item, index) => (
          <div key={index} className="services-impact-card">
            <p className="impact-number">
              {hasAnimated ? (
                <AnimatedNumber number={item.number} />
              ) : (
                0
              )}
            </p>
            <p className="impact-title">{item.title}</p>
          </div>
        ))}
      </div>
    </section>
  );
}

function AnimatedNumber({ number }) {
  const [displayNumber, setDisplayNumber] = useState(0);

  useEffect(() => {
    let start = 0;
    const end = typeof number === "string" ? parseFloat(number) : number;
    const duration = 5000;
    const increment = end / (duration / 50); // Increment every 50ms

    const animate = () => {
      start += increment;
      if (start >= end) {
        setDisplayNumber(number); // Set to final number
      } else {
        setDisplayNumber(Math.floor(start));
        requestAnimationFrame(animate);
      }
    };

    requestAnimationFrame(animate);
  }, [number]);

  return <span>{typeof number === "string" ? number : displayNumber}</span>;
}