import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import blogData from "../../data/blogs.json";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm"; // GitHub-flavored markdown support

export default function BlogPost() {
  const { slug } = useParams(); // Get the blog slug from the URL
  const [markdownContent, setMarkdownContent] = useState(""); // State to store the markdown content

  // Find the blog post by slug
  const blog = blogData.blogs.find((b) => b.slug === slug);

  useEffect(() => {
    if (blog && blog.contentPath) {
      // Dynamically fetch the markdown content from the contentPath
      fetch(blog.contentPath)
        .then((res) => res.text())
        .then((text) => setMarkdownContent(text))
        .catch((err) => console.error("Error loading markdown file:", err));
    }
  }, [blog]);

  if (!blog) {
    return <div>Blog post not found</div>;
  }

  return (
    <article className="blog-post">
      <img className="blog-post-cover" src={blog.cover} alt={blog.title} />
      <div className="blog-post-content">
        <h1 className="blog-post-title">{blog.title}</h1>
        <div className="blog-post-meta">
          <p className="blog-post-author">By <b>{blog.author}</b></p>
          <p className="blog-post-date">{new Date(blog.date).toLocaleDateString()}</p>
          <div className="blog-post-tags">
            {blog.tags.map((tag, index) => (
              <span key={index} className="blog-post-tag">{tag}</span>
            ))}
          </div>
        </div>
        {/* Render the Markdown content here */}
        <ReactMarkdown remarkPlugins={[remarkGfm]}>{markdownContent}</ReactMarkdown>
      </div>
    </article>
  );
}